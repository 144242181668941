.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-pre-line {
  white-space: pre-line !important; }

.text-pre-wrap {
  white-space: pre-wrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.u-flex {
  display: flex; }

.u-flex-wrap {
  flex-wrap: wrap; }

.u-flex-align-center {
  align-items: center; }

.u-flex-end {
  justify-content: flex-end; }

.u-flex-start {
  justify-content: flex-start; }

.u-flex-center {
  justify-content: center; }

.u-flex-space-between {
  justify-content: space-between; }

.u-flex-no-shrink {
  flex-shrink: 0; }

.u-scroll {
  overflow: auto;
  height: 100%; }

.u-overflow-auto {
  overflow: auto; }

.pointer {
  cursor: pointer; }

.w50 {
  width: 50%; }

.w100 {
  width: 100%; }

.position-relative {
  position: relative; }

/**

  Examples:

  @include above(lg) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $space-large;
  }

 */
.sortable-ghost {
  background: #FEE9AF; }

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  outline: none;
  cursor: pointer;
  background-color: #f6f6f6;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  white-space: nowrap;
  position: relative;
  max-height: 100%;
  line-height: 1rem;
  min-width: 2rem; }
  .Button:hover, .Button:focus {
    background-color: #e6e6e6;
    border-color: #c6c6c6; }
  .Button:before {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: #fff;
    opacity: 0.35;
    content: "";
    border-radius: inherit;
    z-index: 1;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    pointer-events: none;
    display: none; }
  .Button a {
    color: inherit;
    text-decoration: inherit; }

.vPrimary {
  background: #1890ff;
  color: #fff;
  border-color: #1890ff; }
  .vPrimary:hover, .vPrimary:focus {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff; }

.vPlain {
  background: transparent;
  border-color: transparent;
  position: relative; }
  .vPlain:hover, .vPlain:focus {
    background: transparent;
    border-color: transparent; }
  .vPlain:hover::after {
    opacity: 0.1; }
  .vPlain::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    border-radius: 5px;
    transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    background: #000; }

.vAction {
  background: #EC808D;
  color: #fff;
  border-color: transparent;
  position: relative; }
  .vAction:hover, .vAction:focus {
    color: #EC808D;
    background-color: #fff;
    border-color: #EC808D; }
  .vAction:hover::after {
    opacity: 0.1; }
  .vAction::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    border-radius: 5px;
    transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    background: #000; }

.disabled {
  cursor: no-drop; }
  .disabled:before {
    display: block; }

.loading {
  cursor: wait; }
  .loading:before {
    display: block; }

.icon {
  display: flex;
  align-items: center; }

.iconWithMargin {
  margin-right: 0.5rem; }

.small {
  padding: 0.25rem 1rem;
  font-size: .8rem;
  border-radius: 4px; }
  .small .iconWithMargin {
    margin-right: 0.25rem; }

.large {
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.5rem; }
  .large .iconWithMargin {
    margin-right: 1rem; }
