.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-pre-line {
  white-space: pre-line !important; }

.text-pre-wrap {
  white-space: pre-wrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.u-flex {
  display: flex; }

.u-flex-wrap {
  flex-wrap: wrap; }

.u-flex-align-center {
  align-items: center; }

.u-flex-end {
  justify-content: flex-end; }

.u-flex-start {
  justify-content: flex-start; }

.u-flex-center {
  justify-content: center; }

.u-flex-space-between {
  justify-content: space-between; }

.u-flex-no-shrink {
  flex-shrink: 0; }

.u-scroll {
  overflow: auto;
  height: 100%; }

.u-overflow-auto {
  overflow: auto; }

.pointer {
  cursor: pointer; }

.w50 {
  width: 50%; }

.w100 {
  width: 100%; }

.position-relative {
  position: relative; }

/**

  Examples:

  @include above(lg) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $space-large;
  }

 */
.sortable-ghost {
  background: #FEE9AF; }

.Root {
  background: #fff;
  border-radius: 5px;
  margin: 1rem;
  padding: 1rem;
  border-top: 1px solid #dedede;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  .Root.noShadow {
    box-shadow: none;
    border: 1px solid #dedede; }
  .Root.noMargin {
    margin: 0; }
  .Root.noPadding {
    padding: 0; }

.hoverable {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .hoverable:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.withPaddingBottom {
  padding-bottom: 3rem; }

.stretched {
  height: 100%; }

.raised {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

.BoxTitle {
  max-width: 50%; }

.BoxSubtitle {
  max-width: 50%; }

.BoxFooter {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  text-align: right;
  color: #08C;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center; }

.paper:before, .paper:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  background: #666;
  box-shadow: 0 15px 10px #666;
  transform: rotate(-3deg); }

.paper:after {
  transform: rotate(3deg);
  right: 10px;
  left: auto; }

.dirty {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset; }
  .dirty:before, .dirty:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: 50%;
    bottom: 0;
    left: 10px;
    right: 10px;
    border-radius: 100px / 10px; }
  .dirty:after {
    right: 10px;
    left: auto;
    transform: skew(8deg) rotate(3deg); }

.header {
  margin-bottom: 1.5rem; }

.headerWithBackground {
  background: #A8C5DB;
  /* box-shadow: 0 1px 1px #dedede; */
  margin: -1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #dedede; }

.headerOneLine {
  display: flex;
  justify-content: space-between;
  align-items: center; }
