.AddressContainer {
  margin-block-end: 1rem;
  overflow: hidden;
  .street {
    padding: 0.5rem;
    background-color: rgba($color: #000000, $alpha: 0.2);
    width: 100%;
  }
  .additionalInfo {
    padding: 0.5rem;
    font-size: 0.8rem;
  }
  .footer {
    width: 100%;
    display: flex;
    .buttons {
      display: inline-block;
      .buttonContainer {
        display: inline-block;
      }
    }
    .geolocalization {
      display: inline-block;
      flex-grow: 1;
    }
  }
}