@import '../lib/ui/styles/variables';

// Prefixes
$prefix: 'data-bp'; // prefix layout attibute

// Grid Variables
$container-width: 1200px;
$gutter: $space;
$cols: 12;

// Breakpoint Variables
$no-break: 0;
$sm-break: 480px;
$md-break: 720px;
$lg-break: 960px;
$xl-break: 1440px;

// Spacing Variables
$xs-spacing: $space-small-x;
$sm-spacing: $space-small;
$md-spacing: $space;
$lg-spacing: $space-large;

// Size Suffixes
$xs-suffix: '--xs';
$sm-suffix: '--sm';
$md-suffix: '';
$lg-suffix: '--lg';
$none-suffix: '--none';

// Location Suffixes
$no-suffix: '';
$top-suffix: '-top';
$bottom-suffix: '-bottom';
$left-suffix: '-left';
$right-suffix: '-right';

// Lists
$location-suffixes: $no-suffix, $top-suffix, $bottom-suffix, $right-suffix, $left-suffix;

// Maps
$spacing-map: (
  $xs-suffix: $xs-spacing,
  $sm-suffix: $sm-spacing,
  $md-suffix: $md-spacing,
  $lg-suffix: $lg-spacing,
  $none-suffix: 0,
);
