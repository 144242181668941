.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}

.w-full {
  width: 100%;
}

.grow-0 {
  flex-grow: 0;
}
.grow-1 {
  flex-grow: 1;
}
