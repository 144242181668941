.locationItem {
  background-color: #f4f4f4;
  padding: 0.5rem;
  border-radius: 4px;
  display: block;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0.5rem;
  &:hover {
    background-color: #ccc;
  }
}