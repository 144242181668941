.print-only {
  display: none;
}

@media print {
  body > *:not([data-printable]) {
    display: none;
  }

  .print-only {
    display: block;
  }
}
