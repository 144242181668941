@import '~antd/dist/antd.css';
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham.css';

@import './styles/blueprint.config';
@import '~blueprint-css/src/_base';
@import '~blueprint-css/src/_column-generator';
@import '~blueprint-css/src/_grid';
@import '~blueprint-css/src/_util';
@import '~blueprint-css/src/_spacing';

@import './styles/ui/forms';
@import './styles/components/location';
@import './styles/components/address-container';
@import './styles/helper-classess';
@import './styles/print';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
  overflow: hidden;
}

@import 'lib/ui/ui.scss';

// fix headers margins
// todo - use custom elements instead of native headers
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// 3rd party overwrites

.ant-table-tbody td {
  vertical-align: top;
}

// ag-grid - adidtional row classess
.order-delivered {
  background: #bde4fb !important;
}
.order-highlighted {
  background: #fbb6a8 !important;
}

// additional styles for maplab

.map-icon {
  background: #404040;
  border-radius: 50%;
  font-size: 13px;
  color: #fff;
  border: 1px solid #fff;
  line-height: 24px;
  padding: 0 2px;
  white-space: nowrap;
  text-align: center;
}

.map-driver-icon {
  background: #0094FF;
  border-radius: 4px;
  width: auto !important;

  &--busy {
    background: #FF00DC;
  }

  &--outdated {
    background: #ccc;
  }

  &--old {
    background: #666;
  }
}

.map-restaurant-icon {
  background: red;
  text-align: center;

  &--task {
    background: #DE5F1F;
  }
}

.map-order-icon {

  &--assigned {
    background: #fF00DC;
  }

  &--task {
    background: #ABA39D;
  }
}